import * as S from "./Description.style.js";
// import Desc_1 from "./../../assets/Desc_1.png";
// import Desc_2 from "./../../assets/Desc_2.png";
// import Desc_3 from "./../../assets/Desc_3.png";
// import Desc_4 from "./../../assets/Desc_4.png";

import Animation from "react-animate-on-scroll";

const Description = (data) => {
  return (
    <S.Container
      style={{
        backgroundImage: 'url("' + data.description.descBack.src + '")',
      }}
    >
      <S.Box>
        <S.Content>
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            {data.isTempImage ? <S.HeadImage src={data.tempImgUrl} /> : <S.Heading>{data.description.heading} </S.Heading>}
            <S.Para>{data.description.mainText}</S.Para>
            <S.Para>
              <ul >
                {data.description.textPoints.map((val) => (
                  <li style={{fontFamily: "DM Sans", lineHeight: "32px"}}>{val.title}</li>
                ))}
              </ul>
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Image src={data.description.descFore} />
          </Animation>
        </S.Content>
      </S.Box>

      {/* <S.Box>
        <S.Content className="hideMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_2} />
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Heading> Why First Imperial </S.Heading>
            <S.Para>
              <ol>
                <li>
                  {" "}
                  <b>On-time deliveries</b>
                  <br />
                  Because of our track record of on-time delivery, we guarantee
                  customer happiness on every purchase.{" "}
                </li>
                <br />
                <li>
                  <b>Superior Quality </b>
                  <br />
                  Best choice for top-quality products. We ensure scales of
                  operations, consistent product quality, and fast production
                  time.
                </li>
                <br />
                <li>
                  <b>Best-in-class Technology</b>
                  <br />
                  We have always believed in investing in newer and more
                  efficient technologies that focus on high quality and
                  cost-competitive manufacturing.
                </li>
                <br />
                <li>
                  <b>Research & Development</b>
                  <br />
                  Specialized technologists help to deliver customized
                  tailor-made solutions with a well-equipped R&D Centre.
                </li>
              </ol>
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content className="showMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_2} />
          </Animation>
        </S.Content>
      </S.Box>

      <S.Box>
        <S.Content>
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Heading> Vision </S.Heading>
            <S.Para>
              Our vision is to continuously strive to innovate our processes,
              products, and services to generate value for money in all our
              efforts - both internal and external. Focusing all our efforts
              toward customer satisfaction, we consider total employee
              involvement, sincerity of purpose, and commitment to our core
              objectives as the foundations of our vision.
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Image src={Desc_3} />
          </Animation>
        </S.Content>
      </S.Box>

      <S.Box>
        <S.Content className="hideMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_4} />
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Heading> Our dealings </S.Heading>
            <S.Para>
              <ul>
                <li>Film and rolls of LDPE, LLDPE, HMHDPE, HDPE</li>
                <li>
                  Purges and lumps of LDPE, HDPE, PP, HIPS, GPPS, ABS, PC and
                  Nylon6
                </li>
                <li>
                  Grindings and sweeping of LDPE HDPE, PP, HIPS, GPPS, ABS, PC
                  and Nylon6
                </li>
                <li>Articles of industrial plastics </li>
              </ul>
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content className="showMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_4} />
          </Animation>
        </S.Content>
      </S.Box> */}

      {/* <S.Content style={{ width: "80%" }}>
        <Animation animateIn="animate__fadeInUp" animateOnce={true}>
          <S.Heading>Our dealings</S.Heading>
          <S.Para>
            <ul>
              <li>Film and rolls of LDPE, LLDPE, HMHDPE, HDPE</li>
              <li>
                Purges and lumps of LDPE, HDPE, PP, HIPS, GPPS, ABS, PC and
                Nylon6
              </li>
              <li>
                Grindings and sweeping of LDPE HDPE, PP, HIPS, GPPS, ABS, PC and
                Nylon6
              </li>
              <li>Articles of industrial plastics </li>
            </ul>
          </S.Para>
        </Animation>
      </S.Content> */}
    </S.Container>
  );
};

export default Description;
