import { createStitches } from "@stitches/react";

export const { styled, css, globalCss, getCssString, theme, keyframes } =
  createStitches({
    theme: {
      colors: {
        black: "#030303",
        white: "#FAFAFD",
        primary: "#363E3D",
        primary_light: "#F34E6D",
        secondary: "#37958F",
        font: "#292C3E",
        grey: "#1D2624",
        button: "#000000",
        bodyColor: "rgba(33, 36, 39, 1)",
        bodyInverted: "rgba(253, 253, 253, 1)",
        footerBg: "#141616",
      },

      fonts: {
        font: "Julius Sans One, sans-serif",
        secondary: "DM Sans,sans-serif",
        code: "monospace",
      },
      fontSizes: {
        1: "14px",
        2: "16px",
        3: "20px",
        4: "24px",
        5: "36px",
        6: "48px",
        7: "54px",
        8: "22px",
        9: "18px",
        10: "21px",
        11: "28px"
      },
    },
  });

export const Layout = styled("div", {
  position: "relative",
  overflow: "hidden",
  maxWidth: "1440px",
  margin: "0 auto",
});
