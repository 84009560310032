import * as S from "./Footer.style.js";
import sourcewiz from "../../static/assets/sourcewiz.png";
import { useEffect, useState } from "react";
import { Row, Col } from "antd";

const links = [
  {
    title: "Home",
    link: "/#home",
  },
  {
    title: "Products",
    link: "/#products",
  },
  {
    title: "About us",
    link: "/#about",
  },
  {
    title: "Contact us",
    link: "/#contact",
  },
];

const Footer = ({
  logo,
  companyName,
  prodArray,
  brandArray,
  isFooterOptional,
  socialArray,
  followus,
  footerImg,
  email,
  smartphoneIcon,
  mailIcon,
  phoneArray,
}) => {
  let [currentYear, setCurrentYear] = useState(0);

  useEffect(() => {
    let date = new Date().getFullYear();
    setCurrentYear(date);
  }, []);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Box>
            <S.CompanyCred>
              <S.Content>
                <S.Image
                  src={footerImg}
                  style={{ width: "220px", height: "170px" }}
                />

                {/* <S.Header> {companyName}</S.Header> */}
              </S.Content>
              {isFooterOptional ? (
                <>
                  {socialArray.map((value) => (
                    <S.social>
                      <S.Image
                        src={value.icon}
                        style={{ width: "13.26px", height: "13.29px" }}
                      />
                      <S.socialText>{value.title}</S.socialText>
                    </S.social>
                  ))}

                  <S.phone>
                    <S.Image
                      src={smartphoneIcon}
                      style={{ width: "13.26px", height: "13.29px" }}
                    />
                    {phoneArray.map((value) => (
                      <S.socialText>{value.phone}</S.socialText>
                    ))}
                  </S.phone>

                  <S.mail>
                    <S.Image
                      src={mailIcon}
                      style={{ width: "13.26px", height: "13.29px" }}
                    />
                    {email.map((val, index) => (
                      <S.socialText>
                        {val.mail} {index < email.length - 1 && " |"}
                      </S.socialText>
                    ))}
                  </S.mail>

                  <S.mobileMail>
                    {email.map((val, index) => (
                      <div style={{ display: "table" }}>
                        <img
                          src={mailIcon}
                          alt="mail-icon"
                          style={{
                            width: "13.26px",
                            height: "13.29px",
                            display: "table-cell",
                            margin: "0 10px 0 0",
                          }}
                        />

                        <S.socialText
                          style={{
                            display: "table-cell",
                            margin: "0 0 10px 0",
                          }}
                        >
                          {val.mail}
                        </S.socialText>
                      </div>
                    ))}
                  </S.mobileMail>

                  <S.follow>
                    Follow us:
                    {followus.map((val) => (
                      <a href={val.link} target="blank">
                        <S.Image
                          src={val.channel}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </a>
                    ))}
                  </S.follow>
                </>
              ) : null}
              <S.Content>
                <div
                  style={{
                    margin: "0.6em 3em 0.6em 0",
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                  }}
                >
                  Powered by
                  <a href="https://sourcewiz.co/" target="blank">
                    <S.Image src={sourcewiz} style={{ margin: "auto 0.5em" }} />
                  </a>
                </div>
              </S.Content>
            </S.CompanyCred>
          </S.Box>

          <S.Box>
            <S.BoldText>Products</S.BoldText>
            {prodArray.map((value, key) => (
              <S.ListText href={value.link} target="blank">
                {value.title}
              </S.ListText>
            ))}
          </S.Box>

          {/* <S.Box>
                    <S.BoldText>Brands we work with</S.BoldText>
                    <S.Content>
                        <S.BrandBox>{brandArray.map((value, key) => <S.ListText href={value.link} target="blank">{value.title}</S.ListText>)}</S.BrandBox>
                    </S.Content>
                </S.Box> */}

          <S.Box>
            <S.BoldText>Quick links</S.BoldText>
            {links.map((value, key) => (
              <S.ListText href={value.link}>{value.title}</S.ListText>
            ))}
          </S.Box>
        </S.Content>
      </S.Container>
      <S.Copyright style={{ fontFamily: "DM Sans" }}>
        Copyright @{companyName} {currentYear - 1}-{currentYear}
      </S.Copyright>
    </>
  );
};

export default Footer;
