import { ThemeProvider } from "styled-components";
import * as S from "./HeroBackground.style.js";

const HeroBackground = (props) => {
  const { background, foreground, mainText, subText, isHeaderOptional, subOptionalHeadText, subOptionalMainText, type = "normal", subOptionalMainText2 } = props;
  const theme = {
    Header: {
      font: "100px",
    },
  };
  console.log(foreground);
  return (
    <ThemeProvider theme={theme}>
      <S.Container
        type={type}
        style={{
          backgroundImage: 'url("' + background + '")',
        }}
        id="home"
      >
        <S.Content>
          <S.Header type={type} className="heading">
            {mainText}
          </S.Header>
          <S.Para>{subText}</S.Para>
          {
            isHeaderOptional ?
            <>
                <S.Para style={{color: '#4F5655', padding:"20px 0"}}>{subOptionalHeadText}</S.Para>
                <S.Para style={{fontWeight: "500"}} >{subOptionalMainText}</S.Para>
                <S.Para style={{fontWeight: "500", paddingBottom: "30px"}} >{subOptionalMainText2}</S.Para>
              </>
              : null
            }
            <S.Button href="/#products">View products</S.Button>
        </S.Content>
        <S.Image src={foreground} />
      </S.Container>
    </ThemeProvider>
  );
};

export default HeroBackground;
