import Logo from "./assets/logo.svg";
import Background from "./assets/Hero_Background.png";
import Hero_Foreground from "./assets/Hero_Foreground.jpg";
import Product_1 from "./assets/Product_1.jpg";
import Product_2 from "./assets/Product_2.jpg";
import Product_3 from "./assets/Product_3.jpg";
import Product_4 from "./assets/Product_4.jpg";
import Product_5 from "./assets/Product_5.jpg";
import Product_6 from "./assets/Product_6.jpg";
import Product_7 from "./assets/Product_7.jpg";
import Product_8 from "./assets/Product_8.jpg";
import Product_9 from "./assets/Product_9.jpg";
import Product_10 from "./assets/Product_10.jpg";
import Product_11 from "./assets/Product_11.jpg";
import Product_12 from "./assets/Product_12.jpg";

import About_1_Background from "./assets/About_1_Background.png";
import About_1_Foreground from "./assets/About_1_Foreground.png";

import Description_1_Background from "./assets/Description_1_Background.png";
import Description_1_Foreground from "./assets/Description_1_Foreground.png";
import Sustainability_1_Background from "./assets/Sustainability_1_Background.png";
import Sustainability_1_Foreground from "./assets/Sustainability_1_Foreground.png";
import Artisan_Foreground from "./assets/Artisans_foreground.png";
import Artisan_Background from "./assets/Artisans_background.png";

import Certification_1 from "./assets/Certifications_1.jpeg";
import Certification_2 from "./assets/Certifications_2.jpg";
import Certification_3 from "./assets/Certifications_3.jpg";
import Certification_4 from "./assets/Certifications_4.jpg";
import Certification_5 from "./assets/Certifications_5.jpg";
import Certification_6 from "./assets/Certifications_6.jpg";
import Certification_7 from "./assets/Certifications_7.jpg";


import About_1 from "./assets/About_1.png";
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";

import tempIMG from "./assets/Temp_Image.png";

import insta from "./assets/instagram.svg";
import linkedin from "./assets/linkedin.svg";
import mail from "./assets/mail.svg";
import map from "./assets/map-pin.svg";
import phone from "./assets/phone.svg";
import smartphone from "./assets/smartphone.svg";
import youtube from "./assets/youtube.svg";

import footerLogo from "./assets/Footer_Logo.svg";

const data = {
  companyName: "Sankh Overseas",
  mainText:
    "Producing Eco-friendly Jute & Cotton Bags that are Right for Your Needs",
  subText:
    "Leading supplier and manufacturer of premium  quality jute & cotton bags. We cater to all kinds of businesses - big or small.",
  productSectionTitle: "Our Products",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Compliances and Certifications",
  buttonContent: "Send an enquiry",
  logo: Logo,
  background: Background,
  foreground: Hero_Foreground,
  about: {
    heading: "About us",
    mainText:
      "Sankh Overseas was established in 2019 to offer an eco-friendly solution to single-use plastic bags. Since then, it has become one of the leading suppliers and manufacturers of reusable jute canvas bags and other made-up items. With over 25 years of experience in exporting jute and canvas bags, the company has achieved high success and credibility in the international market by providing quality products, great range, timely deliveries, excellent services and value pricing irrespective of the volumes. We cater to all kinds of businesses - big or small. Our in-house capacity is over 4.5 million bags per year catering to Europe, the USA, the UK, Southeast Asia, and other parts of the world. <br><br> &nbsp;&bull;&nbsp;In-house design team &nbsp;&bull;&nbsp; Bespoke services &nbsp;&bull;&nbsp; Ethical Trading &nbsp;&bull;&nbsp; Value pricing &nbsp;&bull;&nbsp; Great range &nbsp;&bull;&nbsp; Timely deliveries &nbsp;&bull;&nbsp; Quality products and services &nbsp;&bull;&nbsp; 100% in-house manufacturing",
    mainTextSmall:
      "Sankh Overseas was established in 2019 to offer an eco-friendly solution to single-use plastic bags. Since then, it has become one of the leading suppliers and manufacturers of reusable jute canvas bags and other made-up items. With over 25 years of experience in exporting jute and canvas bags, the company has achieved high success and credibility in the international market by providing quality products, great range, timely deliveries, excellent services and value pricing irrespective of the volumes. We cater to all kinds of businesses - big or small. Our in-house capacity is over 4.5 million bags per year catering to Europe, the USA, the UK, Southeast Asia, and other parts of the world. <br> &nbsp;&bull;&nbsp;In-house design team &nbsp;&bull;&nbsp; Bespoke services &nbsp;&bull;&nbsp; Ethical Trading &nbsp;&bull;&nbsp; Value pricing &nbsp;&bull;&nbsp; Great range &nbsp;&bull;&nbsp; Timely deliveries &nbsp;&bull;&nbsp; Quality products and services &nbsp;&bull;&nbsp; 100% in-house manufacturing",
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: "Vision",
    mainText: `We provide complete customization services for our entire range of
    products. You can choose from various materials (cotton canvas,
    jute/hessian, juco), weights, and sizes to produce bags that define you
    and your brand. Our cotton tote bags come in different weights [from lightweight (100 gsm) canvas whole shoppers to heavyweight (480 gsm) canvas shoppers], recycled, unbranded, or fully customized screen-printed bags, to match all your brand needs.
    `,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
    textPoints: [
      { title: "Bespoke designs" },
      { title: "Sampling available" },
      { title: "4-8 weeks lead " },
      { title: "Minimum order 1000pcs" },
      { title: "Pantone match Azo-free Screen Printing & dyeing" },
      { title: "In-house Packaging and Inspection" },
      { title: "Delivery around the globe" },
      { title: "Private labelling and tagging" },
    ],
  },
  artisans: {
    heading: "Ethical commitment ",
    mainText:
      "We have the utmost love and respect for the people who craft our products. With independent audits and accreditation bodies, we support fair wages, no child labor, workers' right, keep our workplaces safe and hygienic with adequate fire exits, safe machinery and no danger from hazardous chemicals. We treat all our suppliers and workers with respect and our integrity as an ethical supplier is based on this. ",
    foregroundImage: Artisan_Foreground,
    backgroundImage: Artisan_Background,
  },
  sustainability: {
    heading: "Sustainability",
    mainText:
      "We are dedicated to producing and delivering high-quality items to our consumers. Our goal is to satisfy the needs of our customers on time and to strive for continual development. Our products are one-of-a-kind because of the long hours of effort, combined with a humble desire to deliver the best to our customers all around the world",
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],
  prodArray: [
    {
      title: "Promotional & shopping",
      image: Product_1,
      link: "https://catalogue.sourcewiz.co/Above-Bags/5mzxa6y",
    },
    {
      title: "Beach & fashion",
      image: Product_2,
      link: "https://catalogue.sourcewiz.co/Above-Bags/lpdzozg",
    },
    {
      title: "Cooler lunch bag",
      image: Product_3,
      link: "https://catalogue.sourcewiz.co/Above-Bags/ugrjo3d",
    },
    {
      title: "Cosmetic pouches & clutches",
      image: Product_4,
      link: "https://catalogue.sourcewiz.co/Above-Bags/bbhf9n1 ",
    },
    {
      title: "Holiday & Gifting",
      image: Product_5,
      link: "https://catalogue.sourcewiz.co/Above-Bags/kg5b7s5",
    },
    {
      title: "Drawstring",
      image: Product_6,
      link: "https://catalogue.sourcewiz.co/Above-Bags/7lknipj",
    },
    {
      title: "Wine bottle",
      image: Product_7,
      link: "https://catalogue.sourcewiz.co/Above-Bags/a11oapb",
    },
    {
      title: "Table & Kitchen Linens",
      image: Product_8,
      link: "https://catalogue.sourcewiz.co/Above-Bags/gq3shk9",
    },
    {
      title: "Planter Bags",
      image: Product_9,
      link: "https://catalogue.sourcewiz.co/Above-Bags/wj6dp5t",
    },
    {
      title: "Handloom Baskets",
      image: Product_10,
      link: "https://catalogue.sourcewiz.co/Above-Bags/y1gxsbp",
    },
    {
      title: "Baskets and Organizers",
      image: Product_11,
      link: "https://catalogue.sourcewiz.co/Above-Bags/a9ph53d",
    },
    {
      title: "Reused Bags",
      image: Product_12,
      link: "https://catalogue.sourcewiz.co/Above-Bags/lzi5d1a",
    },
  ],
  imgArray: [
    {
      image: Certification_1,
    },

    {
      image: Certification_2,
    },

    {
      image: Certification_3,
    },
    {
      image: Certification_4,
    },
    {
      image: Certification_5,
    },
    {
      image: Certification_6,
    },
    {
      image: Certification_7,
    },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },

  emails: ["manoj@sankh.com"],

  isHeaderOptional: true,
  subOptionalHeadText: "Come Visit us",
  subOptionalMainText: "Bharat Tex 2024 (26th - 29th Feb 2024)",
  subOptionalMainText2: "Mega Show Hong Kong (20th - 23rd October 2024)",
  isTempImage: true,
  tempImgUrl: tempIMG,

  isFooterOptional: true,

  socialArray: [
    {
      icon: map,
      title: "05-5 Emami Commercial Tower, Kolkata, WB",
    },
    // {
    //   icon: phone,
    //   title: "+91 33 40699729",
    // },
  ],
  smartphoneIcon: smartphone,
  mailIcon: mail,
  email: [
    { mail: "apoorvaswami@sankh.com" },
    { mail: "manoj@sankh.com" },
    { mail: "apoorva@abovebags.com" },
  ],

  phoneArray: [{ phone: "+91 9868782603" }, { phone: "+91 9911781361" }],

  followus: [
    {
      channel: insta,
      link: "https://www.instagram.com/sankh_bags/",
    },
    {
      channel: linkedin,
      link: "https://www.linkedin.com/company/sankh-overseas-pvt-ltd/",
    },
    {
      channel: youtube,
      link: "https://www.youtube.com/channel/UCuLS4YuxzEcoXV1tVpQtLrQ",
    },
  ],

  footerImg: footerLogo,
};

export default data;
