import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "90vw",
  zIndex: -1,
  padding: "3em 0",
  background: "White",
  margin: "6em auto",
  maxWidth: "1440px",
  "@media only screen and (max-width : 600px)": {
    paddingBottom: "0 !important",
    marginBottom: "0",
  },
});

export const Header = styled("h2", {
  fontFamily: "$secondary",
  textAlign: "center",
  "@media only screen and (max-width : 600px)": {
    fontSize: "$3",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  width: "80vw",
  margin: "3em 0",
  flexDirection: "row",
  "@media only screen and (max-width : 600px)": {},
});

export const Product = styled("a", {
  display: "flex",
  width: "25vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "3em",
  textDecoration: "none",
  "@media only screen and (max-width : 600px)": {
    width: "40vw",
  },
});

export const Image = styled("img", {
  width: "80%",
  margin: "0 1em",
  "@media only screen and (max-width : 600px)": {
    width: "90%",
  },
});

export const Title = styled("h3", {
  fontFamily: "$secondary",
  fontWeight: "700",
  
  lineHeight: "21px",
  margin: "0.6em",
  color: "#232221",
  marginTop: "3em",
  "@media only screen and (max-width : 600px)": {
    textAlign: "center",
    fontSize : "$2"
  },
});

export const Link = styled("a", {
  textDecoration: "none",
  color: "$primary",
  fontFamily: "$secondary",
});

export const Button = styled("a", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(29, 38, 36, 1)",
  borderRadius: "40px",
  fontSize: "$9",
  lineHeight: "32px",
  fontWeight: "700",
  fontFamily: "$secondary",
  height: "3em",
  padding: "0.5em 5em",
  margin: "1em",
  border: "none",
  color: "$white",
  textDecoration: "none",
  "&:hover": {
    color: "$white",
  },
  "@media only screen and (max-width : 600px)": {
    fontSize: "$1",
    padding: "0.5em 2em",
  },
});
